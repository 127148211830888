import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import styled from "styled-components"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { Card, CardContent, CardHeader } from "@mui/material"
import { Neutral350Text } from "../style/text"
import { isMobile } from "react-device-detect"
// app:javascript:utils:components:widget:BoardWidget.tsx
const debug = Debug("app:javascript:utils:components:widget:BoardWidget")
debug.log = console.log.bind(console)

const SAcUnitIcon = styled(AcUnitIcon)`
    color: white;
`

const ColoredCard = styled(Card)`
    background: linear-gradient(
        60deg,
        ${({ colorDark }: any) => colorDark},
        ${({ colorLight }: any) => colorLight}
    ) !important;
    height: 70px;
    width: 70px;
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
` as any

const CardContentHeader = styled(CardContent)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCard = styled(Card)`
    overflow: visible !important;
    margin-top: 3rem;
	max-width: 100%;
	max-height: ${isMobile ? "400px" : "380px"};
	flex-grow: 1;
    ${({ responsiveHeight }: any) => {
		if (responsiveHeight) {
			return `
            	    height: fit-content;
					max-height: initial;
            	`
		}
	}}
	${({ fullHeight }: any) =>
		fullHeight && (`
		height: calc(100% - 3rem) !important;
	`)
	}
` as any

const HeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`

const SCardContent = styled(CardContent)`
    height: inherit;
	padding: 16px;
` as any

const BoardWidget = ({
	colorLight = "var(--primary300)",
	colorDark = "var(--primary600)",
	icon = <SAcUnitIcon />,
	children = <></>,
	title,
	subTitle = "",
	responsiveHeight = true,
	fullHeight = false,
}) => {
	return (
		<SCard responsiveHeight={responsiveHeight} fullHeight={fullHeight}>
			<CardContentHeader>
				<ColoredCard
					colorDark={colorDark}
					colorLight={colorLight}
					elevation={4}
				>
					{icon}
				</ColoredCard>
				<HeadContainer>
					<div>{title}</div>
					<Neutral350Text>{subTitle}</Neutral350Text>
				</HeadContainer>
			</CardContentHeader>
			<SCardContent>{children}</SCardContent>
		</SCard>
	)
}

export default BoardWidget
