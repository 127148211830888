import DataSaverOnIcon from "@mui/icons-material/DataSaverOn"
import { Autocomplete, IconButton, styled, TextField } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import React, { useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import PointChip from "utils/components/jsonSchema/PointChip"
import useGetPointSummary from "utils/components/jsonSchema/useGetPointSummary"
import { Flex } from "utils/components/style/flex"
import Defer from "utils/Defer"
import getImage from "@/utils/getImage"
import FModalPoint from "./modal/FModalPoint"
import _ from "lodash"
import { Image } from "@nextui-org/react"
const SListSubheader = styled(ListSubheader)`
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: initial;
    top: -8px;
`

const SListItem = styled(ListItem)`
    padding-left: 22px !important;
`

const ListboxComponent = ({ children, ...props }) => {
	return (
		<div {...props}>
			<Defer chunkSize={1}>{children}</Defer>
		</div>
	)
}

function FPoint({
	name,
	label,
	isMulti = false,
	onOpenDetails = () => {},
	onCloseDetails = () => {},
}) {
	const { control } = useFormContext()
	const points = usePoints()
	const getPointSummary = useGetPointSummary()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const [isOpen, setIsOpen] = useState(false)

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: isMulti ? [] : null,
	})

	const getGroup = (option) => {
		return getPointSummary(points[option])?.jsonSchemaTitle
	}

	const options = useMemo(() => {
		return Object.values(points)
			.filter((item) => item.jsonschema_id)
			.filter((item) => jsonSchemasWithStatics[item.jsonschema_id])
			.map((item) => item.id)
			.sort((a, b) => getGroup(a).localeCompare(getGroup(b)))
	}, [points])

	const filteredValue = isMulti
		? (value ?? []).filter((pointId) => points[pointId])
		: value

	return (
		<>
			<Autocomplete
				disableClearable
				multiple={isMulti}
				freeSolo
				ListboxComponent={ListboxComponent}
				fullWidth
				value={filteredValue}
				groupBy={(option) => getGroup(option)}
				options={options}
				onChange={(event, newValue) => onChange(newValue)}
				getOptionLabel={(option) => {
					const title = getPointSummary(points[option])?.title
					if (_.isArray(title)) {
						return title[0]
					}
					return title
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						error={Boolean(error?.message)}
						helperText={error?.message}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{params.InputProps.endAdornment}
									<IconButton
										onClick={() => {
											setIsOpen(true)
											onOpenDetails?.()
										}}
									>
										<DataSaverOnIcon />
									</IconButton>
								</React.Fragment>
							),
						}}
					/>
				)}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<PointChip
							key={option}
							point={points[option]}
							variant="outlined"
							{...getTagProps({ index })}
						/>
					))
				}
				renderOption={(props, option) => {
					const title = getPointSummary(points[option]).title
					return (
						<SListItem {...props} key={option}>
							<ListItemText
								primary={title === "" ? <i>Titre indisponible</i> : title}
							/>
						</SListItem>
					)
				}}
				renderGroup={(params) => {
					// const imgId = Object.values(jsonSchemasWithStatics).find(
					// 	(item) => item.title === params.group,
					// )?.imgId
					// debugger
					return (
						<>
							<SListSubheader>
								<Flex gap="1rem">
									<Image
										height="25"
										width="25"
										src={getImage(
											Object.values(jsonSchemasWithStatics).find(
												(item) => item.title === params.group,
											)?.imgId,
										)}
									/>
									{params.group}
								</Flex>
							</SListSubheader>
							<Defer>{params.children}</Defer>
						</>
					)
				}}
			/>
			<FModalPoint
				isMulti={isMulti}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
					onCloseDetails?.()
				}}
				value={value}
				onChange={onChange}
			/>
		</>
	)
}

export default FPoint
