import {
	Autocomplete,
	Checkbox,
	IconButton,
	InputAdornment,
	TextField,
} from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import React, { useCallback, useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"
import { Flex } from "@/utils/components/style/flex"
import Defer from "@/utils/Defer"
import getImage from "@/utils/getImage"
import styled from "styled-components"
import { StyledChip } from "@/utils/components/style/chip"
import _ from "lodash"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import StaticFormsService from "@/utils/services/StaticFormsService"
import { Image } from "@nextui-org/react"
const SubInformation = styled.div`
    font-size: 0.8rem;
    color: var(--neutral800);
`
const SCheckbox = styled(Checkbox)`
    padding: 0 !important;
`

const ListboxComponent = ({ children, ...props }) => {
	return (
		<div {...props}>
			<Defer>{children}</Defer>
		</div>
	)
}

function FAdminJsonSchemas({
	name,
	label,
	multiple = false,
	disabled = false,
	commune_id = undefined,
	countPoints = false,
	additionalFilter = undefined,
	optionsOverride = undefined,
	endAdornment = undefined,
	additionalList = [],
	onItemClickCallback = () => null,
}) {
	const { control } = useFormContext()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const communes = useAdminCommunes()
	const points = usePoints()
	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: multiple ? [] : undefined,
	})

	const countNumberOfConcernedPoints = useCallback(
		(jsonSchemaId) => {
			return Object.values(points).filter(
				(point) => point.jsonschema_id === jsonSchemaId,
			)?.length
		},
		[points],
	)

	const options = useMemo(() => {
		if (optionsOverride) {
			return optionsOverride
		}
		let optionsToReturn = Object.keys(jsonSchemasWithStatics)
		if (!commune_id) {
			optionsToReturn = optionsToReturn.sort((a, b) =>
				jsonSchemasWithStatics[a]?.title?.localeCompare(
					jsonSchemasWithStatics[b].title,
				),
			)
		} else {
			optionsToReturn = optionsToReturn
				.filter((key) => {
					if (StaticFormsService.isStaticForm(key)) {
						return true
					}
					return (communes[commune_id]?.jsonSchemaIds ?? []).includes(key)
				})
				.sort((a, b) =>
					jsonSchemasWithStatics[a].title.localeCompare(
						jsonSchemasWithStatics[b].title,
					),
				)
		}
		if (!additionalFilter) {
			return [...additionalList, ...optionsToReturn]
		}
		return [...additionalList, ...optionsToReturn.filter(additionalFilter)]
	}, [jsonSchemasWithStatics, commune_id, additionalFilter, additionalList])
	return (
		<Autocomplete
			ListboxComponent={ListboxComponent}
			disabled={disabled}
			readOnly={disabled}
			disableClearable
			fullWidth
			multiple={multiple}
			value={value ?? null}
			options={options ?? []}
			onChange={(event, newValue) => {
				onChange(newValue)
				onItemClickCallback()
			}}
			getOptionLabel={(option) =>
				jsonSchemasWithStatics[option]?.title ?? option
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={Boolean(error?.message)}
					helperText={error?.message}
					{...(multiple
						? {}
						: {
								InputProps: {
									...params.InputProps,
									endAdornment: endAdornment,
									startAdornment: value && (
										<Image
											src={getImage(jsonSchemasWithStatics[value]?.imgId)}
											height={25}
											width={25}
										/>
									),
								},
							})}
					{...(endAdornment
						? {
								InputProps: {
									...params.InputProps,
									endAdornment: endAdornment,
								},
							}
						: {})}
				/>
			)}
			renderOption={(props, option) => {
				if (additionalList.includes(option)) {
					return (
						<li {...props} key={option}>
							<Flex gap={5}>
								{multiple && (
									<SCheckbox
										checked={value?.includes(option)}
										readOnly
										style={{ marginRight: 8 }}
									/>
								)}
								{/* <IconButton size="small">
									<img src={getImage(jsonSchemasWithStatics[option]?.imgId)} />
								</IconButton> */}
								{option}
							</Flex>
						</li>
					)
				}
				const schema = jsonSchemasWithStatics[option]
				if (_.isEmpty(schema)) {
					return null
				}
				let numberOfConcernedPoints = 0
				if (countPoints) {
					numberOfConcernedPoints = countNumberOfConcernedPoints(schema.id)
					if (numberOfConcernedPoints === 0) {
						return null
					}
				}

				return (
					<li {...props} key={option}>
						<Flex gap={5}>
							{multiple && (
								<SCheckbox
									checked={value?.includes(option)}
									readOnly
									style={{ marginRight: 8 }}
								/>
							)}
							<Image src={getImage(schema?.imgId)} height={25} width={25} />
							{schema.title}
							{countPoints && (
								<SubInformation>
									{" "}
									({countNumberOfConcernedPoints(schema.id)} éléments)
								</SubInformation>
							)}{" "}
						</Flex>
					</li>
				)
			}}
			{...(multiple
				? {
						renderTags: (value, getTagProps) => {
							return value.map((option, index) => {
								const schema = jsonSchemasWithStatics[option]
								if (additionalList.includes(option)) {
									return (
										<Flex key={option} gap={5} {...getTagProps({ index })}>
											<StyledChip
												variant="outlined"
												label={option}
												{...(disabled
													? {}
													: {
															onDelete: () => {
																onChange(value.filter((v) => v !== option))
																onItemClickCallback()
															},
														})}
											/>
										</Flex>
									)
								}
								if (_.isEmpty(schema)) {
									return null
								}
								return (
									<Flex key={option} gap={5} {...getTagProps({ index })}>
										<StyledChip
											variant="outlined"
											label={
												`${schema.title} ${
													countPoints
														? " (" +
															countNumberOfConcernedPoints(schema.id) +
															" contacts)"
														: ""
												}` ?? ""
											}
											avatar={
												<Image
													src={getImage(schema?.imgId)}
													height={25}
													width={25}
												/>
											}
											{...(disabled
												? {}
												: {
														onDelete: () => {
															onChange(value.filter((v) => v !== option)),
																onItemClickCallback()
														},
													})}
										/>
									</Flex>
								)
							})
						},
					}
				: {})}
		/>
	)
}

export default FAdminJsonSchemas
