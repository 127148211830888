import BlockIcon from "@mui/icons-material/Block"
import { Autocomplete, TextFieldVariants } from "@mui/material"
import useCommunesCdc from "@/hooks/useCdc"
import React from "react"
import { isMobile } from "react-device-detect"
import SearchBarTextField from "./SearchBarTextField"
import ICommune from "utils/types/ICommune"

const SearchCommune = ({
	options,
	communes,
	setFilter,
	placeholder,
	filter = undefined,
	multiple = true,
	exclude = false,
	autoFocus = false,
	variant = "outlined",
	small = false,
}: {
	options: string[]
	communes: ICommune[]
	setFilter: (filter: string | string[]) => void
	placeholder: string
	filter?: string[]
	multiple?: boolean
	exclude?: boolean
	autoFocus?: boolean
	variant?: TextFieldVariants
	small?: boolean
}) => (
	<Autocomplete
		size={small ? "small" : undefined}
		autoHighlight
		limitTags={isMobile ? 1 : 2}
		value={filter}
		onChange={(event, newValue) => {
			setFilter(newValue)
		}}
		multiple={multiple}
		getOptionLabel={(option) => communes.find((c) => c.id === option).name}
		fullWidth
		options={options}
		ChipProps={{
			icon: exclude ? <BlockIcon /> : undefined,
		}}
		disableCloseOnSelect
		renderInput={(params) => (
			<SearchBarTextField
				autoFocus={autoFocus}
				variant={variant}
				{...params}
				placeholder={placeholder}
			/>
		)}
	/>
)
export default SearchCommune
