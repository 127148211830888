import React from "react"
import { Tooltip as NTooltip } from "@nextui-org/react"

const Tooltip = React.forwardRef<
	HTMLDivElement,
	React.ComponentProps<typeof NTooltip>
>((props, ref) => {
	return (
		<NTooltip
			{...props}
			content={<div className="border border-white p-1">{props.content}</div>}
			ref={ref}
		/>
	)
})
export default Tooltip
