import useWhyDidYouUpdate from "@/hooks/useWhyDidYouUpdate"
import {
	CompositeDecorator,
	Editor,
	EditorState,
	Modifier,
	RichUtils,
} from "draft-js"
import React, { createContext, useRef, useState } from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import styled from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import NewStyledInputLayout from "utils/form/formUtils/NewStyledInputLayout"
import {
	colorHtmlToStyle,
	colorStyleToHTML,
	customStyleFn,
	getCurrentColors,
} from "./draftExtend/color"
import {
	LinkDecorator,
	linkEntityToHTML,
	linkHtmlToEntity,
} from "./draftExtend/link"
import {
	PointDecorator,
	pointEntityToHTML,
	pointHtmlToEntity,
} from "./draftExtend/point"
import combine from "./draftExtend/utils/combine"
import useDraftHTML from "./draftExtend/utils/useDraftHTML"
import EditorToolbar from "./EditorToolbar"
import LinkModal from "./pointTextLink/LinkModal"
import PointTextLinkModal from "./pointTextLink/PointTextLinkModal"
import { FixOlUlStyle } from "../../utils/FixOlUlStyle"
import Button from "@/styles/atoms/Button"

interface IModalPoint {
	isOpen: boolean
	entity:
		| {
				key: string
				data: {
					point: string
					format: string
				}
		  }
		| undefined
}

export const EditorContext = createContext<{
	editorState: EditorState
	setEditorState: (es: EditorState) => void
	focusEditor: () => void
	modalPoint: IModalPoint
	setModalPoint: (mp: IModalPoint) => void
}>(undefined)

export const emptyHTML = (html: string) => {
	const div = document.createElement("div")
	div.innerHTML = html ?? ""
	return div.innerText.replace("\n", "") === ""
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
`

const EditorWrapper = styled.div`
    padding: 9px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const EditorStyle = styled.div`
    padding: 7.5px 5px;
    cursor: text;

    & .public-DraftStyleDefault-block {
        margin: 0 0 0.5em 0 !important;
    }
    & .public-DraftStyleDefault-ul,
    & .public-DraftStyleDefault-ol {
        margin: 0 0 0.5em 0 !important;

        & .public-DraftStyleDefault-block {
            margin: 0 !important;
        }
    }

    &
        [data-contents]
        > [data-block]:last-child
        > .public-DraftStyleDefault-block,
    & .public-DraftStyleDefault-ul:last-child {
        margin: 0 !important;
    }
`

const decorator = new CompositeDecorator([PointDecorator, LinkDecorator])

function FDraft({
	name,
	label,
	toolbarPosition = "top",
	extraToolbarButtons = [],
	hideToolbarButtons = [],
	isImportable = true,
	disabled = false,
}: {
	name: string
	label: string
	toolbarPosition?: "top" | "bottom"
	extraToolbarButtons?: string[]
	hideToolbarButtons?: string[]
	isImportable?: boolean
	disabled?: boolean
}) {
	const editorRef = useRef(undefined)

	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	useWatch({
		control,
		name,
	})

	const [isDisplayedToolbar, setIsDisplayedToolbar] = useState(false)
	const [modalPoint, setModalPoint] = useState<IModalPoint>({
		isOpen: false,
		entity: undefined,
	})
	const [linkEntity, setLinkEntity] = useState(undefined)

	const handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command)
		if (newState) {
			setEditorState(newState)
			return "handled"
		}
		return "not-handled"
	}

	const handleInsertData = (point: string, format: string) => {
		const contentState = editorState.getCurrentContent()
		if (modalPoint.entity) {
			// Replace edited entity data
			const contentStateUpdated = contentState.mergeEntityData(
				modalPoint.entity.key,
				{ point, format },
			)
			setEditorState(
				EditorState.push(editorState, contentStateUpdated, "apply-entity"),
			)

			setModalPoint({ ...modalPoint, isOpen: false })
			return
		}

		// Create new entity
		const contentStateWithEntity = contentState.createEntity(
			"POINT",
			"SEGMENTED",
			{ point, format },
		)
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

		const selection = editorState.getSelection()
		const collapsedSelection = selection.merge({
			focusOffset: selection.getAnchorOffset(),
		})

		const textWithEntity = Modifier.insertText(
			contentStateWithEntity,
			collapsedSelection,
			"\u200B",
			null,
			entityKey,
		)

		setEditorState(
			EditorState.push(editorState, textWithEntity, "insert-characters"),
		)

		setModalPoint({ ...modalPoint, isOpen: false })
		setTimeout(() => editorRef.current.focus(), 0)
	}

	const handleInsertLink = (href: string, name: string) => {
		// Create new entity
		const selection = editorState.getSelection()
		if (!href) {
			setEditorState(RichUtils.toggleLink(editorState, selection, null))
			return
		}
		const contentState = editorState.getCurrentContent()
		const contentStateWithLink = contentState.createEntity(
			"LINK",
			"IMMUTABLE",
			{ href: href, target: "_blank", name: name },
		)

		const entityKey = contentStateWithLink.getLastCreatedEntityKey()
		const collapsedSelection = selection.merge({
			focusOffset: selection.getAnchorOffset(),
		})

		const textWithEntity = Modifier.insertText(
			contentStateWithLink,
			collapsedSelection,
			name,
			null,
			entityKey,
		)

		const newEditorState = EditorState.push(
			editorState,
			textWithEntity,
			"insert-characters",
		)

		setEditorState(newEditorState)

		setLinkEntity(undefined)
		setTimeout(() => editorRef.current.focus(), 0)
	}

	const { editorState, setEditorState } = useDraftHTML({
		htmlValue: value,
		onHtmlChange: onChange,
		decorator: decorator,
		entityToHTML: combine(linkEntityToHTML, pointEntityToHTML),
		htmlToEntity: combine(linkHtmlToEntity, pointHtmlToEntity),
		styleToHTML: colorStyleToHTML,
		htmlToStyle: colorHtmlToStyle,
	})

	const currentStyle = editorState.getCurrentInlineStyle()
	const currentColors = getCurrentColors(currentStyle)

	return (
		<EditorContext.Provider
			value={{
				editorState,
				setEditorState,
				focusEditor: () => editorRef.current.focus(),
				modalPoint,
				setModalPoint,
			}}
		>
			<FixOlUlStyle>
				<Wrapper>
					<EditorToolbar
						currentStyle={currentStyle}
						currentColors={currentColors}
						extraButtons={extraToolbarButtons}
						hideButtons={hideToolbarButtons}
						setLinkEntity={setLinkEntity}
						showToolbar={
							isDisplayedToolbar && toolbarPosition === "top" && !disabled
						}
					/>
					<NewStyledInputLayout
						label={label}
						error={Boolean(error)}
						helperText={error?.message}
						empty={emptyHTML(value)}
						disabled={disabled}
						onClick={() => setIsDisplayedToolbar(true)}
						onBlur={() => setIsDisplayedToolbar(false)}
					>
						<EditorWrapper onClick={() => editorRef.current.focus()}>
							<EditorStyle>
								<Editor
									ref={editorRef}
									editorState={editorState}
									onChange={setEditorState}
									handleKeyCommand={handleKeyCommand}
									customStyleFn={customStyleFn}
								/>
							</EditorStyle>
							{isImportable && (
								<Button
									color="primary"
									variant="bordered"
									size="small"
									fullWidth
									onClick={() =>
										setModalPoint({
											isOpen: true,
											entity: undefined,
										})
									}
								>
									Insérer une donnée
								</Button>
							)}
						</EditorWrapper>
					</NewStyledInputLayout>
					{isDisplayedToolbar && toolbarPosition === "bottom" && !disabled && (
						<EditorToolbar
							currentStyle={currentStyle}
							currentColors={currentColors}
							extraButtons={extraToolbarButtons}
							hideButtons={hideToolbarButtons}
							setLinkEntity={setLinkEntity}
						/>
					)}
				</Wrapper>
			</FixOlUlStyle>
			<PointTextLinkModal
				open={modalPoint.isOpen}
				onInsert={handleInsertData}
				onCancel={() => setModalPoint({ ...modalPoint, isOpen: false })}
				initialValue={modalPoint.entity?.data}
			/>
			<LinkModal
				open={!!linkEntity}
				onInsert={handleInsertLink}
				onCancel={() => setLinkEntity(undefined)}
				initialValue={linkEntity?.data}
			/>
		</EditorContext.Provider>
	)
}

export default FDraft
