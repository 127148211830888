import { FormControlLabel, InputLabel, Switch } from "@mui/material"
import Debug from "debug"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"

// app:javascript:utils:form:FSwitch.tsx
const debug = Debug("app:javascript:utils:form:FSwitch")
debug.log = console.log.bind(console)

const Container = styled.div`
    width: 100%;
`

/**
 * @param {*} { name, label = undefined }
 * @return {*}
 */
const FSwitch = ({
	name,
	label = undefined,
	onCheckChange = undefined,
	labelRight = false,
	disabled = false,
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { ref, value, onChange, ...inputProps },
	} = useController({
		name,
		control,
		defaultValue: false,
	})

	return labelRight ? (
		<FormControlLabel
			control={
				<Switch
					checked={value}
					onChange={(e) => {
						onChange(e.target.checked)
						onCheckChange?.(e.target.checked)
					}}
				/>
			}
			label={label ?? name}
		/>
	) : (
		<Container>
			<InputLabel error={errors[name]}>{label ?? name}</InputLabel>
			<Switch
				checked={value}
				onChange={(e) => {
					onChange(e.target.checked)
					onCheckChange?.(e.target.checked)
				}}
				name="checkedA"
				inputProps={{ "aria-label": "secondary checkbox" }}
				disabled={disabled}
			/>
		</Container>
	)
}

export default FSwitch
