import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import IMappingTool from "../IMappingTool"

const phoneFromMultipleText: IMappingTool<never, never, "fields"> = {
	id: "phoneFromMultipleText",
	name: "Téléphones dans plusieurs champs",
	config: {},
	singleInputs: {},
	arrayInputs: { fields: "Champs*" },
	outputs: [JsonSchemaPropertiesType.PHONE],
	builder:
		({ arrayInputsKeys: { fieldsKeys } }) =>
		async ({ arrayInputs: { fields } }) => {
			return fieldsKeys
				.map((model, index) => ({
					Type: model,
					Numéro: fields[index],
				}))
				.filter((phone) => phone.Numéro)
		},
}

export default phoneFromMultipleText
