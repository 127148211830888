import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"

const textFromText: IMappingTool<never, "field", never> = {
	id: "textFromText",
	name: "Texte simple",
	config: {},
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [
		JsonSchemaPropertiesType.TEXT,
		JsonSchemaPropertiesType.TEXTAREA,
		JsonSchemaPropertiesType.CREATABLE_SELECT,
	],
	builder:
		({ jsonSchemaProperty }) =>
		({ singleInputs: { field } }) => {
			if (!field)
				return jsonSchemaProperty.isRequired
					? Promise.reject(PROP_REQUIRED)
					: Promise.resolve("")
			return Promise.resolve(field)
		},
}

export default textFromText
