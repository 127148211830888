import _ from "lodash"
import Yup from "utils/yup"
import { OptionalObjectSchema } from "yup/lib/object"
import checkboxFromText from "./checkbox/fromText"
import geolocFromAddrLatLng from "./geoloc/fromAddrLatLng"
import geolocFromAddrOnly from "./geoloc/fromAddrOnly"
import humansFromText from "./humans/fromText"
import IMappingTool from "./IMappingTool"
import numberFromText from "./number/fromText"
import phoneFromMultipleText from "./phone/fromMultipleText"
import phoneFromOneText from "./phone/fromOneText"
import radioOrSelectFromText from "./radioOrSelect/fromText"
import stockPlaceFromText from "./stockplace/fromText"
import tagsFromText from "./text/tags/fromText"
import textFromText from "./text/fromText"
import key from "./text/key"

const mappingTools = Object.fromEntries(
	[
		key,
		geolocFromAddrOnly,
		geolocFromAddrLatLng,
		textFromText,
		phoneFromMultipleText,
		phoneFromOneText,
		radioOrSelectFromText,
		checkboxFromText,
		numberFromText,
		tagsFromText,
		stockPlaceFromText,
		humansFromText,
	].map((mappingTool) => [
		mappingTool.id,
		mappingTool as IMappingTool<any, any, any>,
	]),
)

const isRequired = (label: string) => label.endsWith("*")

export const mappingToolInstanceValidator = Yup.object({
	converter: Yup.string().oneOf(Object.keys(mappingTools)).required(),
	config: Yup.object().when(
		"converter",
		(converter: string, schema: OptionalObjectSchema<never>) => {
			if (!converter) return schema
			const mappingTool = mappingTools[converter]

			if (Object.keys(mappingTool.config).length > 0) {
				return schema
					.shape(
						_.mapValues(mappingTool.config, (val) =>
							isRequired(val) ? Yup.string().required() : Yup.string(),
						),
					)
					.required()
			}
			return schema.optional()
		},
	),
	singleInputs: Yup.object().when(
		"converter",
		(converter: string, schema: OptionalObjectSchema<never>) => {
			if (!converter) return schema
			const mappingTool = mappingTools[converter]

			if (Object.keys(mappingTool.singleInputs).length > 0) {
				return schema
					.shape(
						_.mapValues(mappingTool.singleInputs, (val) =>
							isRequired(val) ? Yup.string().required() : Yup.string(),
						),
					)
					.required()
			}
			return schema.optional()
		},
	),
	arrayInputs: Yup.object().when(
		"converter",
		(converter: string, schema: OptionalObjectSchema<never>) => {
			if (!converter) return schema
			const mappingTool = mappingTools[converter]

			if (Object.keys(mappingTool.arrayInputs).length > 0) {
				return schema
					.shape(
						_.mapValues(mappingTool.arrayInputs, (val) =>
							isRequired(val)
								? Yup.array(Yup.string()).min(1).required()
								: Yup.array(Yup.string()),
						),
					)
					.required()
			}
			return schema.optional()
		},
	),
})
	.default(undefined)
	.transform((val) => {
		if (!val?.converter) return undefined
		return val
	})

export default mappingTools
