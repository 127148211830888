import React from "react"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { DraggableItemContext } from "./makeDraggable"
import Div from "./Div"

function SuperDragHandle({ component = Div, writePermission = true }) {
	if (!writePermission) {
		return null
	}
	const Component = component
	return (
		<DraggableItemContext.Consumer>
			{(value) => (
				<Component {...value}>
					<DragIndicatorIcon />
				</Component>
			)}
		</DraggableItemContext.Consumer>
	)
}

export default SuperDragHandle
