import { Badge, IconButton, Popover } from "@mui/material"
import React, { useContext, useRef, useState } from "react"
import styled from "styled-components"
import ColorPicker from "utils/components/ColorPicker"
import preventDefault from "utils/preventDefault"
import { EditorContext } from "../../FDraft"

const ColouredBadge = styled(Badge)<{ $badgeColor: string }>`
    .MuiBadge-badge {
        background-color: ${({ $badgeColor }) => $badgeColor};
    }
`

const ColorEditorButton = ({ Icon, action, currentColor }) => {
	const editorContext = useContext(EditorContext)

	const [open, setOpen] = useState(false)
	const buttonRef = useRef()

	return (
		<>
			<IconButton
				ref={buttonRef}
				onMouseDown={preventDefault(() => setOpen(true))}
				tabIndex={-1}
			>
				<ColouredBadge
					overlap="circular"
					$badgeColor={currentColor}
					variant="dot"
				>
					<Icon color="action" />
				</ColouredBadge>
			</IconButton>

			<Popover
				onMouseDown={preventDefault()}
				disableAutoFocus
				open={open}
				onClose={() => {
					setOpen(false)
				}}
				anchorEl={buttonRef.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<ColorPicker
					value={undefined}
					setValue={(value: string) => action(value, editorContext)}
				/>
			</Popover>
		</>
	)
}

export default ColorEditorButton
