import { Chip } from "@mui/material"
import React from "react"
import styled from "styled-components"
import useGetPointSummary from "./useGetPointSummary"

const JsonSchemaIcon = styled.img`
    padding-top: 2px;
`

function PointChip({ point, ...props }) {
	const getPointSummary = useGetPointSummary()
	const pointSummary = getPointSummary(point)

	if (!pointSummary) return null

	return (
		<Chip
			label={pointSummary.title}
			icon={<JsonSchemaIcon height="25" src={pointSummary.image} />}
			{...props}
		/>
	)
}

export default PointChip
