import _ from "lodash"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"

const checkboxFromText: IMappingTool<"separator", "field", never> = {
	id: "checkboxFromText",
	name: "Checkboxs dans un seul champs",
	config: { separator: "Séparateur (/)" },
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [JsonSchemaPropertiesType.CHECKBOX],
	builder:
		({ config: { separator = "/" }, jsonSchemaProperty }) =>
		({ singleInputs: { field } }) => {
			if (field === "")
				return jsonSchemaProperty.isRequired
					? Promise.reject(PROP_REQUIRED)
					: Promise.resolve([])

			const selected = field.split(separator).map((s) => s.trim())
			const items = jsonSchemaProperty.items ?? []

			const diff = _.difference(selected, items)

			if (diff.length === 0) return Promise.resolve(selected)
			else
				return Promise.reject({
					cause: "notinlist",
					value: items.filter((item) => selected.includes(item)),
					message:
						diff.length === 1
							? `"${diff[0]}" ne fait pas parti des valeurs autorisées pour ce champs`
							: `"${diff.join(
									", ",
							  )}" ne font pas parti des valeurs autorisées pour ce champs`,
				})
		},
}

export default checkboxFromText
