import styled from "styled-components"
/**
 * ? The app with tailwind need not this style to work correctly
 * ? However we need this for Draft content
 * ? So this class should be wrapped around rendered content of a draft
 */
export const FixOlUlStyle = styled.div`
    width: 100%;
    & ol {
        list-style: decimal !important;
        margin: 0px !important;
        padding: 0px !important;
        padding-left: 1rem !important;
    }
    & ul {
        list-style: disc !important;
        margin: 0px !important;
        padding: 0px !important;
        padding-left: 1rem !important;
}
`
