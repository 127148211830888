import HumanService from "utils/services/HumanService"
import { StaticFormType } from "utils/services/StaticFormsService"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"

const humansFromText: IMappingTool<"separator", "field", never> = {
	id: "humansFromText",
	name: "Annuaire",
	config: { separator: "Séparateur (/)" },
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [JsonSchemaPropertiesType.RESPONSABLES],
	builder: ({ config: { separator = "/" }, jsonSchemaProperty, points }) => {
		const humans = Object.values(points).filter((point) =>
			[
				StaticFormType.ANNUAIRES_ELUS,
				StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
				StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
			].includes(point.jsonschema_id as StaticFormType),
		)

		return ({ singleInputs: { field } }) => {
			if (!field)
				return jsonSchemaProperty.isRequired
					? Promise.reject(PROP_REQUIRED)
					: Promise.resolve([])

			const found = []
			const notFound = []

			field
				.split(separator)
				.map((s) => s.trim())
				.forEach((name) => {
					const human = humans.find(
						(h) => HumanService.displayFullName(h).trim() === name,
					)
					if (human) found.push(human.id)
					else notFound.push(name)
				})

			if (notFound.length === 0) return Promise.resolve(found)
			else
				return Promise.reject({
					cause: "notfound",
					message: `Impossible de trouver les personnes suivantes : ${notFound.join(
						", ",
					)}`,
					value: found,
				})
		}
	},
}

export default humansFromText
