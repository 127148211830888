import _ from "lodash"
import ApiDataGouvAddress from "utils/api_externe/ApiDataGouvAddress"
import { wait } from "utils/wait"
import ErrorService from "@/services/ErrorService"

const NUMBER_OF_REQUEST_PER_GROUP = 30
const NUMBER_OF_MS_BTW_2_REQUEST = 30 //ms
const WAITING_TIME_BTW_2_GROUP = 800 //ms
const MAX_TRY = 10

const process = (dispatch) => {
	const addressesToSearch = Object.keys(process.memory)
		.filter((addr) => {
			return !process.memory[addr].value
		})
		.slice(0, NUMBER_OF_REQUEST_PER_GROUP)

	if (!addressesToSearch.length) {
		process.active = false
		return
	}

	Promise.all(
		addressesToSearch.map(async (addressToSearch, index) => {
			await wait(index * NUMBER_OF_MS_BTW_2_REQUEST)
			const cacheEntry = process.memory[addressToSearch]

			try {
				let resultAddresses
				if (!addressToSearch || addressToSearch === " ") {
					resultAddresses = []
				} else {
					const res = await ApiDataGouvAddress.searchAddressAdmin(
						addressToSearch,
					)
					resultAddresses = res.data.features.map((feature) => ({
						Adresse: feature.properties.label,
						coo: {
							lng: feature.geometry.coordinates[0],
							lat: feature.geometry.coordinates[1],
						},
						score: feature.properties.score,
					}))
				}

				cacheEntry.value = resultAddresses
				cacheEntry.waiting.forEach(({ resolve, reject }) => {
					if (resultAddresses.length) resolve(resultAddresses)
					else reject("nomatch")
				})
				cacheEntry.waiting = undefined
			} catch (e) {
				if (cacheEntry.retry >= MAX_TRY) {
					cacheEntry.waiting.forEach(({ reject, bulkId }) => {
						if (!bulkId || !process.error.includes(bulkId)) {
							process.error.push(bulkId)
							ErrorService.error({
								component: "geocoding::process",
								message:
									"Certaines coordonnées n'ont pas pu être déterminées. Vous n'avez pas de connexion internet, ou le service n'est pas disponible.",
								dispatch,
							})
						}
						reject("network")
					})
					delete process.memory[addressToSearch]
				} else cacheEntry.retry++
			}
		}),
	).then(() => setTimeout(process, WAITING_TIME_BTW_2_GROUP))
}
process.active = false
process.error = [] as string[]
process.memory = {} as {
	[key: string]: {
		value: ScoredAddr[] | undefined
		retry: number
		waiting: {
			resolve: (val: ScoredAddr[]) => void
			reject: (r: string) => void
			bulkId: string
		}[]
	}
}

const startProcessIfNot = _.debounce((dispatch) => {
	if (!process.active) {
		process(dispatch)
	}
}, 100)

export const searchAdress = (addr: string, bulkId = undefined, dispatch) => {
	const cached = process.memory[addr]
	if (cached && cached.value) {
		if (cached.value.length) return Promise.resolve(cached.value)
		else return Promise.reject("nomatch")
	}

	if (!cached) {
		process.memory[addr] = {
			value: undefined,
			retry: 0,
			waiting: [],
		}
	}

	return new Promise<ScoredAddr[]>((resolve, reject) => {
		process.memory[addr].waiting.push({
			resolve,
			reject,
			bulkId,
		})
		startProcessIfNot(dispatch)
	})
}

interface ScoredAddr {
	Adresse: string
	coo: { lat: number; lng: number }
	score: number
}
