import React, { useMemo } from "react"
import PointProperty from "utils/components/jsonSchema/properties/PointProperty"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import IJsonSchema, {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import useSortPoints from "./useSortPoints"
import IPoint from "utils/types/IPoint"
import { ColumnDef } from "@tanstack/react-table"
const useJsonSchemaColumns = (
	jsonSchema: IJsonSchema,
	additionalFilter = undefined,
) => {
	const pointPropertyToText = usePointPropertyToText()
	const multiPolygons = useMultiPolygons()
	const sortFunction = useSortPoints(jsonSchema)
	const columns = useMemo(() => {
		const schemaProperties = jsonSchema?.template?.properties ?? []
		let selectedFields = schemaProperties.filter((el) => el.isColumn)

		if (additionalFilter) {
			selectedFields = selectedFields.filter(additionalFilter)
		}
		return selectedFields.map((field) => ({
			id: field.name,
			header: field.label,
			accessorFn: (data) => pointPropertyToText(data, field),
			cell: ({ row }) => (
				<>
					<PointProperty point={row.original} property={field} editable />
				</>
			),
			sortType: (rowA, rowB, id, desc) => {
				return sortFunction({
					a: rowA.original,
					b: rowB.original,
					forcedProperty: {
						property: field.name,
						desc,
					},
					multiPolygons,
				})
			},
			...(field.size
				? {
						size: field.size,
						minSize: field.size,
						maxSize: field.size,
				  }
				: {}),
			...((field.type === JsonSchemaPropertiesType.GEOLOC ||
				field.type === JsonSchemaPropertiesType.PHONE ||
				field.type === JsonSchemaPropertiesType.TEXTAREA) &&
			!field.isColumnSectorOnly
				? {
						minSize: 300,
						size: 300,
						maxSize: 300,
				  }
				: {}),
		})) as ColumnDef<IPoint, any>[]
	}, [jsonSchema])

	return columns
}

export default useJsonSchemaColumns
