import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import IPoint from "utils/types/IPoint"
import usePoints from "@/hooks/usePoints"
import { useEffectOnce } from "react-use"

// app:javascript:components:admin:formulaire_dynamique:MyForms:import:components:AutoErrorSolver.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:import:components:AutoErrorSolver",
)
debug.log = console.log.bind(console)

const AutoErrorSolver = ({
	mapping,
	mappingResults,
	jsonSchema,
	setMappingResults,
	setCurrentStep,
	currentStep,
}) => {
	// * selector
	const points = usePoints()

	// * useEffect
	useEffectOnce(() => {
		let fieldToKey

		Object.entries(mapping.schema).forEach(([key, item]: any) => {
			if (item?.converter === "key") {
				fieldToKey = key
			}
		})
		const clonedMappingResults = _.cloneDeep(mappingResults)
		const filteredPoints = Object.values(points).filter(
			(point: IPoint) => point.jsonschema_id === jsonSchema.id,
		)
		filteredPoints.forEach((point: IPoint) => {
			Object.values(mappingResults).forEach((mappingResult: any, index) => {
				if (_.isEmpty(mappingResult.errors)) {
					return
				}

				if (
					mappingResult.data[fieldToKey] ===
					point.geojson.properties[fieldToKey]
				) {
					// matching point with mappingResults
					Object.entries(mappingResult.errors).forEach(
						([field, error]: any) => {
							if (error.cause === "multiplechoices") {
								error.props.options.forEach((addressProposition) => {
									if (
										addressProposition?.Adresse ===
										point.geojson.properties[field]?.Adresse
									) {
										console.log(
											"found existing error previously solved for" +
												point.geojson.properties[field]?.Adresse,
										)
										// found a corresponding address, just auto resolve error
										clonedMappingResults[index].data[field] =
											point.geojson.properties[field]
										delete clonedMappingResults[index].errors[field]
									}
								})
							}
						},
					)
				}
			})
		})
		setMappingResults(clonedMappingResults)
		setCurrentStep(currentStep + 1)
	})

	return <></>
}

export default AutoErrorSolver
