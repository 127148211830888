import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import ICommune from "@/utils/types/ICommune"
import useAdminRestrictions from "../useAdminRestrictions"

const selectAdminCommunes = createDeepEqualSelector(
	(state: any) => state.admin.users.communes,
	(communes: {[key: string]: ICommune}) => communes,
)

const useAdminCommunes = (): { [key: string]: ICommune } => {
	const adminRestrictions = useAdminRestrictions()
	const communes = useSelector(selectAdminCommunes)

	const sortedCommunes = Object.fromEntries(
		Object.entries(communes).sort(([, a], [, b]) => a.name.localeCompare(b.name))
	)
	if (!adminRestrictions.isRestricted) return sortedCommunes

	const filtered = {}
	Object.entries(sortedCommunes).forEach(([key, comm]: [string, ICommune]) => {
		if (!adminRestrictions.allowedCommunes.includes(comm.id)) return
		filtered[key] = comm
	})

	return filtered
}

export default useAdminCommunes