import React from "react"
import FormatBoldIcon from "@mui/icons-material/FormatBold"
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
import AddLinkIcon from "@mui/icons-material/AddLink"
import { toggleBlock } from "./draftExtend/block"
import { toggleInlineStyle } from "./draftExtend/inline"
import ColorEditorButton from "./draftExtend/utils/ColorEditorButton"
import SimpleEditorButton from "./draftExtend/utils/SimpleEditorButton"
import styled from "styled-components"
import { toggleColor } from "./draftExtend/color"

export const BOLD = "BOLD"
export const ITALIC = "ITALIC"
export const UNDERLINE = "UNDERLINE"
export const UNORDERED = "unordered-list-item"
export const ORDERED = "ordered-list-item"
export const COLOR = "color"
export const BGCOLOR = "bgcolor"
export const LINK = "LINK"

const Toolbar = styled.div<{ $showToolbar: boolean }>`
	transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Added opacity transition */
    display: flex;
	height: ${({ $showToolbar }) => ($showToolbar ? "40px" : "0")}; /* Increased height for better visibility */
	overflow: hidden;
	opacity: ${({ $showToolbar }) => ($showToolbar ? "1" : "0")}; /* Added opacity change */
`

interface EditorToolbarProps {
	currentStyle: Draft.DraftInlineStyle
	currentColors: { color: string; bgcolor: string }
	extraButtons?: string[]
	hideButtons?: string[]
	setLinkEntity?: React.Dispatch<any>
}

const EditorToolbar = ({
	currentStyle,
	currentColors,
	extraButtons = [],
	hideButtons = [],
	setLinkEntity = undefined,
	showToolbar = false,
}: EditorToolbarProps) => {
	return (
		<Toolbar $showToolbar={showToolbar}>
			{!hideButtons.includes(BOLD) && (
				<SimpleEditorButton
					Icon={FormatBoldIcon}
					action={toggleInlineStyle(BOLD)}
					active={currentStyle.has(BOLD)}
				/>
			)}
			{!hideButtons.includes(ITALIC) && (
				<SimpleEditorButton
					Icon={FormatItalicIcon}
					action={toggleInlineStyle(ITALIC)}
					active={currentStyle.has(ITALIC)}
				/>
			)}
			{!hideButtons.includes(UNDERLINE) && (
				<SimpleEditorButton
					Icon={FormatUnderlinedIcon}
					action={toggleInlineStyle(UNDERLINE)}
					active={currentStyle.has(UNDERLINE)}
				/>
			)}
			{!hideButtons.includes(UNORDERED) && (
				<SimpleEditorButton
					Icon={FormatListBulletedIcon}
					action={toggleBlock(UNORDERED)}
					active={false}
				/>
			)}
			{!hideButtons.includes(ORDERED) && (
				<SimpleEditorButton
					Icon={FormatListNumberedIcon}
					action={toggleBlock(ORDERED)}
					active={false}
				/>
			)}
			{!hideButtons.includes(COLOR) && (
				<ColorEditorButton
					Icon={FormatColorTextIcon}
					action={toggleColor(COLOR)}
					currentColor={currentColors.color}
				/>
			)}
			{!hideButtons.includes(BGCOLOR) && (
				<ColorEditorButton
					Icon={FormatColorFillIcon}
					action={toggleColor(BGCOLOR)}
					currentColor={currentColors.bgcolor}
				/>
			)}
			{extraButtons.includes(LINK) && (
				<SimpleEditorButton
					Icon={AddLinkIcon}
					action={() => setLinkEntity({})}
					active={false}
				/>
			)}
		</Toolbar>
	)
}
export default EditorToolbar
