import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import IMappingTool from "../IMappingTool"

const phoneFromOneText: IMappingTool<
	"separatorBetween" | "separatorInside",
	"field",
	never
> = {
	id: "phoneFromOneText",
	name: "Téléphones dans un seul champs",
	config: {
		separatorBetween: "Séparateur entre téléphones (/)",
		separatorInside: "Séparateur Type/Numéro (:)",
	},
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [JsonSchemaPropertiesType.PHONE],
	builder:
		({ config: { separatorBetween = "/", separatorInside = ":" } }) =>
		async ({ singleInputs: { field } }) => {
			if (!field) return []
			return field.split(separatorBetween).map((phone) => {
				const d = phone.split(separatorInside)
				return {
					Type: d[0]?.trim(),
					Numéro: d[1]?.trim(),
				}
			})
		},
}

export default phoneFromOneText
