import { IconButton } from "@mui/material"
import React, { useContext } from "react"
import preventDefault from "@/utils/preventDefault"
import { EditorContext } from "../../FDraft"

const SimpleEditorButton = ({ Icon, action, active }) => {
	const editorContext = useContext(EditorContext)

	return (
		<IconButton
			tabIndex={-1}
			onMouseDown={preventDefault(() => action(editorContext))}
			color={active ? "primary" : "default"}
		>
			<Icon />
		</IconButton>
	)
}

export default SimpleEditorButton
