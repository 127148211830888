import { ContentState, DraftInlineStyle, EditorState, Modifier } from "draft-js"
import React from "react"

export const colorStyleToHTML = (style: string) => {
	if (style.startsWith("color-")) {
		return <span style={{ color: style.substring(6) }} />
	}
	if (style.startsWith("bgcolor-")) {
		return <span style={{ backgroundColor: style.substring(8) }} />
	}
}

export const colorHtmlToStyle = (nodeName, node, currentStyle) => {
	if (nodeName === "span") {
		if (node.style.color) return currentStyle.add(`color-${node.style.color}`)
		if (node.style.backgroundColor)
			return currentStyle.add(`bgcolor-${node.style.backgroundColor}`)
		else return currentStyle
	} else {
		return currentStyle
	}
}

export const customStyleFn = (style: DraftInlineStyle) => {
	const color: string = style.toJS().find((s: string) => s.startsWith("color-"))
	const bgcolor: string = style
		.toJS()
		.find((s: string) => s.startsWith("bgcolor-"))

	if (color || bgcolor)
		return {
			...(color ? { color: color?.substring(6) } : undefined),
			...(bgcolor ? { backgroundColor: bgcolor?.substring(8) } : undefined),
		}
}

export const toggleColor =
	(type: "color" | "bgcolor") =>
	(color: string, { editorState, setEditorState }) => {
		const selection = editorState.getSelection()
		const currentContent: ContentState = editorState.getCurrentContent()

		const anchorKey = selection.getAnchorKey()
		const currentBlock = currentContent.getBlockForKey(anchorKey)

		const styles = {}
		currentBlock
			.getCharacterList()
			.toJS()
			.forEach((char) => {
				char.style.forEach((s) => (styles[s] = true))
			})

		const toRemove = Object.keys(styles).filter((s: string) =>
			s.startsWith(`${type}-`),
		)
		const nextContentState = toRemove.reduce(
			(contentState: ContentState, color: string) => {
				return Modifier.removeInlineStyle(contentState, selection, color)
			},
			currentContent,
		)

		if (color) {
			setEditorState(
				EditorState.push(
					editorState,
					Modifier.applyInlineStyle(
						nextContentState,
						selection,
						`${type}-${color}`,
					),
					"change-inline-style",
				),
			)
		} else {
			setEditorState(
				EditorState.push(editorState, nextContentState, "change-inline-style"),
			)
		}
	}

export const getCurrentColors = (inlineStyles) => {
	const color = inlineStyles
		.toJS()
		.find((s: string) => s.startsWith("color-"))
		?.substring(6)
	const bgcolor = inlineStyles
		.toJS()
		.find((s: string) => s.startsWith("bgcolor-"))
		?.substring(8)
	return {
		color,
		bgcolor,
	}
}
