import FormatColorResetIcon from "@mui/icons-material/FormatColorReset"
import { Button } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { PrimaryTextButton } from "./style/button"
import { Flex } from "./style/flex"

const classicColors = [
	"#ff0f17",
	"#ff8021",
	"#ffff37",
	"#00ff30",
	"#02fefe",
	"#0480fb",
	"#0200fb",
	"#8000fb",
	"#ff00fc",
]

const greys = [
	"#ffffff",
	"#dddddd",
	"#cccccc",
	"#aaaaaa",
	"#888888",
	"#666666",
	"#444444",
	"#222222",
	"#000000",
]

export const COLORS = [...greys, ...classicColors]

const ColorItem = styled.div<{
	color: string
	selected: boolean
}>`
    position: relative;
    border: solid lightgrey 1px;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    background-color: ${({ color }) => color};
    cursor: pointer;
    outline: ${({ selected }) => (selected ? "solid black 2px" : "none")};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
`

function ColorPicker({ value, setValue }) {
	return (
		<Wrapper>
			<Flex gap="5px">
				{classicColors.map((color) => (
					<ColorItem
						key={color}
						color={color}
						selected={value === color}
						onClick={() => setValue(color)}
					/>
				))}
			</Flex>
			<Flex gap="5px">
				{greys.map((color) => (
					<ColorItem
						key={color}
						color={color}
						selected={value === color}
						onClick={() => setValue(color)}
					/>
				))}
			</Flex>
			<Flex justifyCenter>
				<PrimaryTextButton
					startIcon={<FormatColorResetIcon />}
					size="small"
					onClick={() => setValue("")}
				>
					Couleur par défaut
				</PrimaryTextButton>
			</Flex>
		</Wrapper>
	)
}

export default ColorPicker
