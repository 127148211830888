import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import {
	Alert,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Paper,
} from "@mui/material"
import { IMappingResult } from "@/pages/admin/mapping/core/MappingSchemaRunner"
import useMappings from "@/hooks/useMappings"
import usePoints from "@/hooks/usePoints"
import React, { useEffect, useMemo, useState } from "react"
import {
	OrangeOutlinedButton,
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import _ from "lodash"
import ParentSubmitButtonWrapper from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitButtonWrapper"
import { ParentSubmitContext } from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitContext"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import ErrorsSolverItem from "./ErrorsSolverItem"

const getErroredRows = (mappingResult: IMappingResult) => {
	return mappingResult
		.filter((item) => Object.keys(item.errors).length > 0)
		.map((item) => item.index)
}

const ErrorsSolver = ({
	mappingResults,
	setMappingResults,
	currentStep,
	setCurrentStep,
	ignorable = false,
	setIgnorredRowsIndex = (_arg) => {},
	jsonSchema,
	ignorredRowsIndex = [],
}) => {
	const [shouldSubmit, setShouldSubmit] = useState(false)

	const [tmpMappingResult, setTmpMappingResult] =
		useState<IMappingResult>(mappingResults)
	const initalErrorNumber = useMemo(
		() => getErroredRows(mappingResults).length,
		[],
	)
	const erroredRows = getErroredRows(tmpMappingResult)
	const id = erroredRows[0]

	const solveRowResult =
		(indexToSolve: number) => (solvingValues: { [key: string]: unknown }) => {
			setShouldSubmit(false)
			let solvedMappingResult = _.cloneDeep(tmpMappingResult)

			const resolvingMappingResult = tmpMappingResult.find(
				(item) => item.index === indexToSolve,
			)
			solvedMappingResult = solvedMappingResult.map((value, index) => {
				Object.entries(value.errors).forEach(([key, error]) => {
					if (
						_.isEqual(
							error.message,
							resolvingMappingResult.errors?.[key]?.message,
						) &&
						Object.keys(solvingValues).includes(key)
					) {
						const copiedErrors = _.cloneDeep(value.errors)
						delete copiedErrors[key]
						value = {
							...value,
							data: {
								...value.data,
								[key]: solvingValues[key],
							},
							errors: copiedErrors,
						}
					}
				})
				return value
			})

			setTmpMappingResult(solvedMappingResult)
		}

	const ignoreRowResult = (indexToIgnore: number) => {
		setTmpMappingResult(
			tmpMappingResult.filter((value) => value.index !== indexToIgnore),
		)
		setIgnorredRowsIndex([...ignorredRowsIndex, indexToIgnore])
	}

	useEffect(() => {
		const nErroredRows = getErroredRows(tmpMappingResult).length
		if (!nErroredRows) {
			setMappingResults(tmpMappingResult.map((item) => item.data))
			setCurrentStep(currentStep + 1)
		}
	}, [tmpMappingResult])

	const solveAdressNoGeoloc = () => {
		const keyToModify = []
		Object.entries(tmpMappingResult[id].errors).forEach(([key, value]) => {
			if (value.cause === "multiplechoices") {
				keyToModify.push(key)
			}
		})

		keyToModify.forEach((key) => {
			if (
				jsonSchema.template.properties.find((property) => property.name === key)
					.type === JsonSchemaPropertiesType.GEOLOC
			) {
				setTmpMappingResult(
					tmpMappingResult.map((item) => {
						if (item.index === id) {
							const copiedErrors = _.cloneDeep(item.errors)
							delete copiedErrors[key]
							return {
								...item,
								data: {
									...item.data,
									[key]: {
										Adresse: tmpMappingResult[id].errors[key].initialValue,
									},
								},
								errors: copiedErrors,
							}
						}
						return item
					}),
				)
			}
		})
	}

	return (
		<Paper>
			<ParentSubmitContext.Provider
				value={{
					onSubmit: solveRowResult(id),
					shouldSubmit,
					setShouldSubmit,
				}}
			>
				<DialogTitle>
					Résolution des erreurs{" "}
					{erroredRows.length ? (
						<>
							({initalErrorNumber - erroredRows.length + 1}/{initalErrorNumber})
						</>
					) : (
						<>(terminée)</>
					)}
				</DialogTitle>
				<LinearProgress
					variant="determinate"
					value={
						((initalErrorNumber - erroredRows.length) / initalErrorNumber) * 100
					}
				/>

				<DialogContent dividers>
					{erroredRows.length ? (
						<ErrorsSolverItem
							key={id}
							erroredItem={tmpMappingResult.find(
								(mappingResult) => mappingResult.index === id,
							)}
							jsonSchemaProperties={jsonSchema.template.properties}
						/>
					) : (
						<Alert severity="success">
							Toutes les données sont a présent valides
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					{ignorable && (
						<OrangeOutlinedButton onClick={() => ignoreRowResult(id)}>
							Ignorer la donnée
						</OrangeOutlinedButton>
					)}
					<ParentSubmitButtonWrapper>
						{({ onClick }) => (
							<Flex gap="2rem">
								{Object.values(tmpMappingResult[id]?.errors ?? []).some(
									(error: any) => error.cause === "multiplechoices",
								) && (
									<PrimaryOutlinedButton onClick={solveAdressNoGeoloc}>
										Copier l&apos;adresse sans la géolocaliser et{" "}
										{erroredRows.length <= 1 ? (
											<>terminer</>
										) : (
											<>passer au suivant</>
										)}
									</PrimaryOutlinedButton>
								)}
								<PrimaryButton
									endIcon={<NavigateNextIcon />}
									onClick={() => {
										onClick()
									}}
									disabled={!erroredRows.length}
								>
									Corriger et{" "}
									{erroredRows.length <= 1 ? (
										<>terminer</>
									) : (
										<>passer au suivant</>
									)}
								</PrimaryButton>
							</Flex>
						)}
					</ParentSubmitButtonWrapper>
				</DialogActions>
			</ParentSubmitContext.Provider>
		</Paper>
	)
}

export default ErrorsSolver
