import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"
import formatNfdLowerCase from "utils/formatNfdLowerCase"

const flexFindItemInArray = ({ array, item }) => {
	let exactIndex = null;
	let matchingIndex = [];

	array.forEach((option, index) => {
		// Case 1: Exact match
		if (option === item) {
			exactIndex = index;
		}

		// Case 2: Check if item is contained in the option
		if (option.includes(item)) {
			matchingIndex.push(index);
		}

		// Case 3: Check if option is contained in the item
		if (item.includes(option)) {
			matchingIndex.push(index);
		}

	});

	if (exactIndex !== null) {
		return exactIndex;
	}

	return matchingIndex.length === 1 ? matchingIndex[0] : null;
}


const radioOrSelectFromText: IMappingTool<never, "field", never> = {
	id: "radioOrSelectFromText",
	name: "Selection dans un seul champs",
	config: {},
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [
		JsonSchemaPropertiesType.RADIO,
		JsonSchemaPropertiesType.SELECT,
		JsonSchemaPropertiesType.SELECTION_WITH_IMAGE,
	],
	builder:
		({ jsonSchemaProperty }) =>
			({ singleInputs: { field } }) => {
				let items = jsonSchemaProperty.items ?? []
				if (jsonSchemaProperty.type === JsonSchemaPropertiesType.SELECTION_WITH_IMAGE) {
					items = jsonSchemaProperty.itemsImage.map(itemImage => itemImage.label)
				}
				items = items.map((item) => formatNfdLowerCase(item))
				if (!field)
					return jsonSchemaProperty.isRequired
						? Promise.reject(PROP_REQUIRED)
						: Promise.resolve("")
				let nfdField = formatNfdLowerCase(field)
				const index = flexFindItemInArray({ array: items, item: nfdField })
				if (index !== null) {
					let originalField = jsonSchemaProperty.items?.[index]
					if (jsonSchemaProperty.type === JsonSchemaPropertiesType.SELECTION_WITH_IMAGE) {
						originalField = jsonSchemaProperty.itemsImage?.[index]?.label
					}
					return Promise.resolve(originalField)
				}

				return Promise.reject({
					cause: "notinlist",
					message: `"${field}" ne fait pas parti des valeurs autorisées pour ce champs`,
				})
			},
}

export default radioOrSelectFromText
