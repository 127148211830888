import React from "react"
import Dropzone, { ErrorCode } from "react-dropzone"
import styled from "styled-components"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"

const MAX_UPLOADED_FILE_SIZE = 5_000_000

const Drop = styled.div`
    height: 160px;
    padding: 0 10px;
    border: grey dashed 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: grey;
`

const FileDropzone = ({ allowedExtension, onFileUpload }) => {
	const dispatch = useDispatch()
	return (
		<Dropzone
			onDropAccepted={(files) => {
				onFileUpload(files[0])
			}}
			onDropRejected={(fileRejections) => {
				const errors = fileRejections.reduce(
					(acc, val) => [...acc, ...val.errors.map((e) => e.code)],
					[],
				) as ErrorCode[]
				if (errors.includes(ErrorCode.TooManyFiles))
					ErrorService.error({
						component: "FileDropzone",
						message: "Erreur, le téléversement est limité à un seul fichier",
						dispatch,
					})
				else if (errors.includes(ErrorCode.FileInvalidType))
					ErrorService.error({
						component: "FileDropzone",
						message: "Erreur, ce type de fichier n'est pas accepté",
						dispatch,
					})
				else if (errors.includes(ErrorCode.FileTooLarge))
					ErrorService.error({
						component: "FileDropzone",
						message:
							"Erreur, les fichiers supérieur à 3Mo ne sont pas acceptés",
						dispatch,
					})
			}}
			accept={{ ...allowedExtension.extensionRefs }}
			multiple={false}
			maxSize={MAX_UPLOADED_FILE_SIZE}
		>
			{({ getRootProps, getInputProps }) => (
				<Drop {...getRootProps()}>
					<input {...getInputProps()} />
					Cliquez ou faites glisser des documents (
					{allowedExtension.extensionNames?.join(", ")})
				</Drop>
			)}
		</Dropzone>
	)
}

export default FileDropzone
