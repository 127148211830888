import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { IAccessRights } from "./services/useAccessRightsServices"

const selectAdmin = createDeepEqualSelector(
    (state: any) => state.userSettings,
    (userSettings) => ({ id: userSettings.id, role: userSettings.role })
)
const selectRestrictions = createDeepEqualSelector(
    (state: any) => state.userSettings.settings.accessRights,
    (accessRights: IAccessRights) => ({ communes: accessRights.communes, cdcs: accessRights.cdcs })
)

const useAdminRestrictions = () => {
    const userSettings = useSelector(selectAdmin) ?? {}
    const accessRights = useSelector(selectRestrictions) ?? []

    return {
        selfId: userSettings.id,
        isRestricted: userSettings.role === 'admin',
        allowedCommunes: accessRights.communes,
        allowedCdcs: accessRights.cdcs
    }
}

export default useAdminRestrictions