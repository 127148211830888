import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"

const geolocFromAddrLatLng: IMappingTool<never, "addr" | "lat" | "lng", never> =
	{
		id: "geolocFromAddrLatLng",
		name: "Adresse avec coordonnées",
		config: {},
		singleInputs: {
			addr: "Adresse",
			lat: "Latitude*",
			lng: "Longitude*",
		},
		arrayInputs: {},
		outputs: [JsonSchemaPropertiesType.GEOLOC],
		builder:
			({ jsonSchemaProperty }) =>
			({ singleInputs: { addr, lat, lng } }) => {
				if (!(lat && lng) && !addr)
					return jsonSchemaProperty.isRequired
						? Promise.reject(PROP_REQUIRED)
						: Promise.resolve({})

				return Promise.resolve({
					Adresse: addr,
					coo: {
						lng: parseFloat(lng.replace(",", ".")),
						lat: parseFloat(lat.replace(",", ".")),
					},
				})
			},
	}

export default geolocFromAddrLatLng
