import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { v4 as uuidv4 } from "uuid"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"
import FromAddrOnly from "./utils/FromAddrOnly"
import { searchAdress } from "./utils/geocoding"

const transformed = (address: string) => {
	let formatedAddress = formatNfdLowerCase(address)
	const transformations = [
		{
			from: " bis",
			to: "bis",
		},
		{
			from: " avenue ",
			to: " rue ",
		},
		{
			from: "rue de ",
			to: "rue ",
		},
		{
			from: " b ",
			to: "bis ",
		},
	]
	formatedAddress = formatedAddress.replace(/ +(?= )/g, "")

	transformations.forEach((transformation) => {
		formatedAddress = formatedAddress
			.split(transformation.from)
			.join(transformation.to)
	})

	return formatedAddress
}

const geolocFromAddrOnly: IMappingTool<"suffix", never, "addr"> = {
	id: "geolocFromAddrOnly",
	name: "Adresse sans coordonnées",
	config: {
		suffix: "Suffixe",
	},
	singleInputs: {},
	arrayInputs: { addr: "Adresse*" },
	outputs: [JsonSchemaPropertiesType.GEOLOC],
	builder: ({ config: { suffix = "" }, jsonSchemaProperty, dispatch }) => {
		const bulkId = uuidv4()

		return ({ arrayInputs: { addr } }) => {
			if (!addr.length)
				return jsonSchemaProperty.isRequired
					? Promise.reject(PROP_REQUIRED)
					: Promise.resolve({})

			const address = addr.filter(Boolean).join(" ")

			return new Promise((resolve, reject) => {
				searchAdress(`${address} ${suffix}`, bulkId, dispatch)
					.then((res) => {
						if (
							res.length === 1 ||
							(res.length > 1 &&
								res[0].score > 0.8 &&
								res[0].score - res[1].score > 0.2)
						) {
							resolve(res[0])
							return
						}

						if (res[0].score > 0.77) {
							resolve(res[0])
						}

						if (transformed(res[0].Adresse) === transformed(address)) {
							resolve(res[0])
							return
						}

						reject({
							cause: "multiplechoices",
							message: `Plusieurs adresses peuvent correspondre à : ${address} ${suffix}`,
							value: res[0],
							initialValue: `${address} ${suffix}`,
							component: FromAddrOnly,
							props: {
								options: [...res, { Adresse: `${address} ${suffix}` }],
							},
						})
					})
					.catch((e) => {
						if (e === "nomatch") {
							reject({
								cause: "nomatch",
								message:
									"Les coordonnées liés à l'adresse n'ont pas pu être trouvées.",
								value: {
									Adresse: `${address} ${suffix}`,
								},
							})
						} else {
							reject({
								cause: "network",
								message:
									"Les coordonnées liés à l'adresse n'ont pas pu être trouvées à cause d'une erreur réseau (vérifiez votre connexion internet). ",
								value: {
									Adresse: `${address} ${suffix}`,
								},
							})
						}
					})
			})
		}
	},
}

export default geolocFromAddrOnly
