import { Autocomplete, ListItem, ListItemText, TextField } from "@mui/material"
import _ from "lodash"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import { v4 as uuidv4 } from "uuid"
const FromAddrOnly = ({
	name,
	property,
	options,
}: {
	name: string
	options: any
	property: IJsonSchemaProperty
}) => {
	const { control } = useFormContext()

	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<Autocomplete
			value={value}
			onChange={(event, newValue) => onChange(newValue)}
			options={options}
			isOptionEqualToValue={(option, value) => {
				return (
					_.isEqual(option.coo, value.coo) && option.Adresse === value.Adresse
				)
			}}
			getOptionLabel={(option) => option?.Adresse}
			renderInput={(params) => (
				<TextField
					{...params}
					error={!!error}
					label={property.label}
					helperText={error?.message}
					inputRef={ref}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<ListItem {...props} key={uuidv4()}>
						<ListItemText
							primary={option.Adresse}
							secondary={
								option.coo &&
								`lat : ${option.coo.lat} / lng : ${option.coo.lng}`
							}
						/>
						{option.score && <div>{option.score.toFixed(2)}</div>}
					</ListItem>
				)
			}}
		/>
	)
}

export default FromAddrOnly
