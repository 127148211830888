import {
	Autocomplete,
	Checkbox,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Radio,
	TextField,
} from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useJsonSchemaColumns from "@/pages/admin/formulaire_dynamique/MyForms/useJsonSchemaColumns"
import useSortPoints from "@/pages/admin/formulaire_dynamique/MyForms/useSortPoints"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import { deletePoint } from "@/redux-toolkit/data/points/resources"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width80 } from "@/utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import ModalBodyForTable from "@/utils/modals/ModalBodyForTable"
import Modal from "@/styles/organisms/Modal"
import { Image, ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"

const FModalPoint = ({ isOpen, onClose, value, onChange, isMulti }) => {
	const onMultipeSelectChange = (row) => {
		const valueChecked =
			(value ?? []).filter((id) => id === row.original.id).length > 0

		if (valueChecked) {
			onChange(value.filter((id) => id !== row.original.id))
			return
		}
		onChange([...(value ?? []), row.original.id])
	}

	const multipleSelectColumn = ({ row }) => {
		const valueChecked =
			(value ?? []).filter((id) => id === row.original.id).length > 0

		return (
			<Checkbox
				checked={valueChecked}
				onChange={() => onMultipeSelectChange(row)}
			/>
		)
	}
	const jsonSchemas = useJsonSchemasWithStatics()
	const points = usePoints()
	const dispatch = useDispatch()

	const [jsonSchemaId, setJsonSchemaId] = useState(undefined)
	useEffect(() => {
		if (isOpen) setJsonSchemaId(undefined)
	}, [isOpen])

	const jsonSchema = jsonSchemas[jsonSchemaId]

	const options = useMemo(() => {
		return Object.values(jsonSchemas).map((jsonSchema) => jsonSchema.id)
	}, [jsonSchemas])

	const jsonSchemaColumns = useJsonSchemaColumns(jsonSchema)
	const sortFunction = useSortPoints(jsonSchema)

	const columns = useMemo(
		() =>
			[
				{
					header: "Choisir",
					accessorKey: "choose",
					cell: isMulti ? multipleSelectColumn : <Radio />,
					...width80,
				},
				...(jsonSchemaId
					? jsonSchemaColumns
					: [
							{
								header: "Aucun tableau séléctionné",
								accessorKey: "no",
								enableSorting: false,
							},
						]),
			] as ColumnDef<any>[],
		[jsonSchemaColumns],
	)

	const data = useMemo(() => {
		return jsonSchemaId
			? Object.values(points)
					.filter((point) => point.jsonschema_id === jsonSchemaId)
					// @ts-ignore
					.sort(sortFunction)
			: []
	}, [points, jsonSchemaId])

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="5xl">
			<ModalContent>
				<ModalHeader>Séléctionner une donnée</ModalHeader>
				<ModalBodyForTable>
					<Flex gap="1rem" directionColumn alignItemsStretch>
						<Autocomplete
							renderInput={(params) => (
								<TextField
									{...params}
									label="Choisir un tableau de données"
									fullWidth
								/>
							)}
							getOptionLabel={(option) => {
								return jsonSchemas[option].title
							}}
							options={options}
							onChange={(event, val) => setJsonSchemaId(val)}
							renderOption={(props, option) => {
								return (
									<ListItem {...props} key={option}>
										<ListItemAvatar>
											<Image
												height={25}
												width={25}
												src={getImage(jsonSchemas[option].imgId)}
											/>
										</ListItemAvatar>
										<ListItemText primary={jsonSchemas[option].title} />
									</ListItem>
								)
							}}
						/>

						<SuperReactTable
							key={jsonSchemaId}
							onRowClick={
								isMulti
									? onMultipeSelectChange
									: (row) => {
											onChange(row.original.id)
											onClose()
										}
							}
							autoResetPage={false}
							selectable={false}
							data={data}
							columns={columns}
							onEditClick={(point) => dispatch(setCurrentPoint(point))}
							onDeleteClick={(point) => dispatch(deletePoint(point))}
						/>
					</Flex>
				</ModalBodyForTable>
				<ModalFooter>
					<PrimaryButton onClick={onClose}>Terminer</PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default FModalPoint
