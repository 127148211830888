import { CircularProgress } from "@mui/material"
import MappingSchemaRunner from "@/pages/admin/mapping/core/MappingSchemaRunner"
import usePoints from "@/hooks/usePoints"
import { useAllTags } from "@/hooks/useTags"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Flex } from "utils/components/style/flex"
import minimumExecutionTime from "utils/minimumExecutionTime"

const MappingRunner = ({
	csvData,
	mappingSchema,
	setMappingResults,
	setCurrentStep,
	currentStep,
	jsonSchema,
}) => {
	const points = usePoints()

	const [percentage, setPercentage] = useState(0)
	const tags = useAllTags()
	const dispatch = useDispatch()

	useEffect(() => {
		const runner = new MappingSchemaRunner(
			mappingSchema,
			jsonSchema.template.properties,
			points,
			tags,
			dispatch,
		)

		minimumExecutionTime(
			runner.convertRows(csvData, (total, completed) =>
				setPercentage((completed / total) * 100),
			),
			1000,
		).then((converted) => {
			setMappingResults(converted)
			setCurrentStep(currentStep + 1)
		})
	}, [])

	return (
		//@ts-ignore
		<Flex padding={[10, 10]} gap>
			<CircularProgress
				variant={percentage === 100 ? "indeterminate" : "determinate"}
				size={40}
				value={percentage}
			/>
			<div>
				Conversion des données en cours. Cette opération peut prendre du temps
				lorsque de nombreuses données doivent être localisées. (
				{percentage.toFixed(0)}%)
			</div>
		</Flex>
	)
}

export default MappingRunner
