import usePoints from "@/hooks/usePoints"
import { Entity } from "draft-js"
import React, { useContext } from "react"
import styled from "styled-components"
import usePointFormat from "utils/components/jsonSchema/usePointFormat"
import { EditorContext } from "../FDraft"
import findEntities from "./utils/findEntities"

export const pointHtmlToEntity = (nodeName, node) => {
	if (nodeName === "span") {
		const point = node.getAttribute("point")
		const format = node.getAttribute("format")

		if (point && format) {
			return Entity.create("POINT", "SEGMENTED", {
				point,
				format,
			})
		}
	}
}

export const pointEntityToHTML = (entity, originalText) => {
	if (entity.type === "POINT") {
		return `<span point="${entity.data.point}" format="${entity.data.format}">${originalText}</span>`
	}
}

const SSpan = styled.span<{ data: string }>`
    cursor: pointer !important;

    &:before {
        background-color: var(--primary100);
        padding: 0 4px;
        border-radius: 4px;
        line-height: initial;
        content: "${({ data }) => data}";
    }
`

export const PointDecorator = {
	strategy: findEntities("POINT"),
	component: (props) => {
		const { setModalPoint } = useContext(EditorContext)
		const { point, format } = props.contentState
			.getEntity(props.entityKey)
			.getData()
		const points = usePoints()
		const formatPoint = usePointFormat()

		return (
			<SSpan
				onClick={() =>
					setModalPoint({
						isOpen: true,
						entity: {
							data: { point, format },
							key: props.entityKey,
						},
					})
				}
				data-offset-key={props.offsetKey}
				data={formatPoint(points[point], format)}
			>
				{props.children}
			</SSpan>
		)
	},
}
