import { convertFromHTML, convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import { useEffect, useRef, useState } from "react"

const useDraftHTML = ({
	htmlValue,
	onHtmlChange,
	decorator,
	entityToHTML,
	styleToHTML,
	htmlToEntity,
	htmlToStyle,
}) => {
	const [editorState, internalSetEditorState] = useState(() =>
		EditorState.createEmpty(decorator),
	)

	const localHtmlValue = useRef<string>(htmlValue)

	const setEditorState = (newEditorState: EditorState) => {
		internalSetEditorState(newEditorState)

		const newHtml = convertToHTML({
			entityToHTML,
			styleToHTML,
		})(newEditorState.getCurrentContent())

		onHtmlChange(newHtml)
		localHtmlValue.current = newHtml
	}

	const htmlFromEditorState = convertToHTML({
		entityToHTML,
		styleToHTML,
	})(editorState.getCurrentContent())

	useEffect(() => {
		//* Checking if the htmlValue exist, else set wisiwyg field to empty
		if (!htmlValue) {
			setEditorState(EditorState.createEmpty(decorator))
			return
		}
		//* Checking if the htmlValue has changed
		if (
			htmlValue === localHtmlValue.current &&
			htmlValue === htmlFromEditorState
		) {
			//* htmlValue has not changed or has already been updated, we do nothing
			//? In reality, just set return can sometimes cancel the changes (ex: Links)
			//? so we update the editorState with his current state to be sure.
			internalSetEditorState(editorState)
		} else {
			//* htmlValue has changed, we need to update the editorState
			const newEditorState = EditorState.createWithContent(
				convertFromHTML({
					htmlToEntity,
					htmlToStyle,
				})(htmlValue),
				decorator,
			)
			internalSetEditorState(newEditorState)
		}
		localHtmlValue.current = htmlValue
	}, [htmlValue, htmlFromEditorState])

	return {
		editorState,
		setEditorState,
	}
}

export default useDraftHTML
