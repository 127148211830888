import { Entity } from "draft-js"
import findEntities from "./utils/findEntities"
import React from "react"

export const linkHtmlToEntity = (nodeName, node) => {
	if (nodeName === "a") {
		return Entity.create("LINK", "IMMUTABLE", {
			href: node.getAttribute("href"),
			target: node.getAttribute("target"),
		})
	}
}

export const linkEntityToHTML = (entity, originalText) => {
	if (entity.type === "LINK") {
		return `<a href="${entity.data.href}" target="${entity.data.target}">${
			originalText.length > 1 ? originalText : entity.data.name
		}</a>`
	}
}

export const LinkDecorator = {
	strategy: findEntities("LINK"),
	component: (props) => {
		const { href, target } = props.contentState
			.getEntity(props.entityKey)
			.getData()

		return (
			<a href={href} target={target} onClick={() => window.open(href)}>
				{props.children}
			</a>
		)
	},
}
