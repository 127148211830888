import { TextField } from "@mui/material"

import styled from "styled-components"

const SearchBarTextField = styled(TextField)`
    height: 100%;
    & > .MuiOutlinedInput-root {
        flex: 1;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`

export default SearchBarTextField
