import React, { useEffect } from "react"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { SmallModal } from "@/utils/components/style/modal"
import { Flex } from "@/utils/components/style/flex"
import FText from "@/utils/form/FText"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import { PrimaryButton } from "@/utils/components/style/button"

const schema = Yup.object().shape({
	url: Yup.string().nullable().required().default(undefined),
	name: Yup.string().required().default(undefined),
})

const LinkModal = ({ open, onInsert, onCancel, initialValue }) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
	})

	useEffect(() => {
		if (initialValue) {
			methods.reset(initialValue)
		} else {
			methods.reset(schema.getDefault())
		}
	}, [open])

	const onSubmit = (values) => {
		onInsert(values.url, values.name)
	}

	return (
		<FormProvider {...methods}>
			<Modal isOpen={open} onClose={onCancel}>
				<ModalContent>
					<ModalHeader>
						{initialValue
							? "Modifier le lien"
							: "Ajouter un lien dans le texte"}
					</ModalHeader>
					<ModalBody>
						<Flex directionColumn gap="1rem" alignItemsStretch>
							<FText name="url" label="URL du lien" />
							<FText name="name" label="Intitulé du lien" />
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={onCancel} />
						<PrimaryButton onClick={methods.handleSubmit(onSubmit)}>
							{initialValue ? "Modifier" : "Ajouter"}
						</PrimaryButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	)
}

export default LinkModal
