import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import React, { useEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import styled from "styled-components"
import usePointFormat from "@/utils/components/jsonSchema/usePointFormat"
import { Flex } from "@/utils/components/style/flex"
import FPoint from "@/utils/form/FPoint"
import Yup from "@/utils/yup"
import FPointFormat from "../../../FPointFormat"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import { PrimaryButton } from "@/utils/components/style/button"
import Button from "@/styles/atoms/Button"

const schema = Yup.object().shape({
	point: Yup.string().nullable().required().default(undefined),
	format: Yup.string().required().default(undefined),
})

const SSpan = styled.span`
    color: #333333;
    background-color: lightgrey;
    padding: 0 4px;
    border-radius: 4px;
`

const InsertPointInfo = () => {
	const { watch, getValues } = useFormContext()
	watch("point")
	watch("format")

	const pointId = getValues("point")
	const points = usePoints()
	const point = points[pointId]
	const format = getValues("format")

	const formatPoint = usePointFormat()
	const formated = formatPoint(point, format)

	return formated ? (
		<Alert severity="success">
			Le texte suivant sera inséré et mis à jour automatiquement si la donnée
			est modifiée :<br />
			<SSpan>{formated}</SSpan>
		</Alert>
	) : (
		<Alert severity="info">
			Séléctionnez une donnée puis choisissez sa mise en forme pour
			l&apos;inclure dans l&apos;action. Le texte inséré sera automatiquement
			mis à jour si la donnée est modifiée.
		</Alert>
	)
}

const PointTextLinkModal = ({
	open,
	onInsert,
	onCancel,
	initialValue = undefined,
}) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
	})
	methods.watch("point")

	useEffect(() => {
		if (initialValue) {
			methods.reset(initialValue)
		} else {
			methods.reset(schema.getDefault())
		}
	}, [open])

	const pointId = methods.getValues("point")
	const points = usePoints()
	const point = points[pointId]

	const onSubmit = (values) => {
		onInsert(values.point, values.format)
	}

	return (
		<FormProvider {...methods}>
			<Modal size="3xl" isOpen={open} onClose={onCancel}>
				<ModalContent>
					<ModalHeader>
						{initialValue
							? "Modifier une donnée insérée"
							: "Insérer une donnée dans le texte"}
					</ModalHeader>
					<ModalBody>
						<Flex directionColumn gap="1rem" alignItemsStretch>
							<FPoint name="point" label="Donnée à insérer" />
							<FPointFormat
								name="format"
								label="Mise en forme de la donnée"
								jsonSchemaId={point?.jsonschema_id}
							/>
							<InsertPointInfo />
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={onCancel} />
						<Button color="primary" onClick={methods.handleSubmit(onSubmit)}>
							{initialValue ? "Modifier" : "Insérer"}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	)
}

export default PointTextLinkModal
