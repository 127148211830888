import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { useCallback } from "react"
import IPoint from "utils/types/IPoint"
import usePointPropertyToText from "./properties/usePointPropertyToText"

export const POINT_FORMAT_FIELD_REGEX = /#\{(?<prop>[^}]+)\}/g

const usePointFormat = () => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const pointPropertyToText = usePointPropertyToText()

	return useCallback(
		(point: IPoint, format: string) => {
			if (!point || !format) return ""

			const jsonSchema = jsonSchemas[point?.jsonschema_id]
			const schemaProperties = jsonSchema?.template?.properties

			if (!schemaProperties) return

			return format.replaceAll(POINT_FORMAT_FIELD_REGEX, (...result) => {
				const prop = result[result.length - 1].prop
				const property = schemaProperties.find(
					(property) => property.name === prop,
				)
				if (!property) return ""
				return pointPropertyToText(point, property).trim()
			})
		},
		[pointPropertyToText],
	)
}

export default usePointFormat
