import { ITag } from "@/hooks/useTags"
import _ from "lodash"
import ErrorService from "@/services/ErrorService"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import { IPoints } from "utils/types/IPoint"
import mappingTools from "../mappingTools/mappingTools"

export type IRow = { [key: string]: string }

export type IRowMappingResult = {
	index: number
	data: { [key: string]: unknown }
	errors: {
		[key: string]: {
			cause: string
			message: string
			value?: unknown
			initialValue?: any
			component?: (props: any) => JSX.Element
			props?: { [key: string]: any }
			forceRequired?: boolean
		}
	}
}

export const PROP_REQUIRED = {
	cause: "required",
	message:
		"Le champs est requis, mais aucune valeur n'a pu être construire à partir des données.",
}

export type IMappingResult = IRowMappingResult[]

class MappingSchemaRunner {
	runner: { [k: string]: (row: IRow) => Promise<unknown> }
	dispatch: (action: any) => void

	constructor(
		mappingSchema: IMappingSchema,
		jsonSchemaProperties: IJsonSchemaProperty[],
		points: IPoints,
		tags: ITag[],
		dispatch,
	) {
		this.dispatch = dispatch
		this.runner = Object.fromEntries(
			Object.entries(mappingSchema)
				.map(([key, mappingSchemaItem]) => {
					if (!mappingSchemaItem) return undefined

					const mappingTool = mappingTools[mappingSchemaItem.converter]

					// === csv headers
					const singleInputsKey = _.mapKeys(
						mappingSchemaItem.singleInputs ?? {},
						(v, key) => `${key}Key`,
					)

					const arrayInputsKeys = _.mapKeys(
						mappingSchemaItem.arrayInputs ?? {},
						(v, key) => `${key}Keys`,
					)
					// ===

					// === json schema property
					const jsonSchemaProperty = jsonSchemaProperties.find(
						(prop) => prop.label === key,
					)
					// ===

					// === config
					const config = mappingSchemaItem.config ?? {}
					// ===

					const preparedTool = mappingTool.builder({
						config,
						arrayInputsKeys,
						singleInputsKey,
						jsonSchemaProperty,
						points,
						tags,
						dispatch,
					})

					const convertRowCell = (row: IRow) => {
						const singleInputs = _.mapValues(
							mappingSchemaItem.singleInputs,
							(val) => {
								return row[val]
							},
						)
						const arrayInputs = _.mapValues(
							mappingSchemaItem.arrayInputs,
							(val) => {
								return val.map((i) => row[i])
							},
						)

						return preparedTool({ singleInputs, arrayInputs })
					}

					return [key, convertRowCell]
				})
				.filter(Boolean),
		)
	}

	async convertRow(row: IRow, index: number): Promise<IRowMappingResult> {
		const promiseResults = await Promise.allSettled(
			Object.entries(this.runner).map(([key, convertRowCell]) => {
				return new Promise<{
					key: string
					result: unknown
				}>((resolve, reject) => {
					try {
						convertRowCell(row)
							.then((result) => resolve({ key, result }))
							.catch((error) => reject({ key, error }))
					} catch (e) {
						ErrorService.error({
							component: "MappingSchemaRunner.convertRow",
							message: e,
							silence: true,
							dispatch: this.dispatch,
						})
						reject({
							key,
							error: { message: "Erreur inconnue." },
						})
					}
				})
			}),
		)
		return {
			index: row?.properties?.lignePos ?? index,
			data: Object.fromEntries(
				promiseResults.map((promiseResult) => {
					if (promiseResult.status === "fulfilled")
						return [promiseResult.value.key, promiseResult.value.result]
					return [promiseResult.reason.key, undefined]
				}),
			),
			errors: Object.fromEntries(
				promiseResults
					.map((promiseResult) => {
						if (promiseResult.status === "rejected")
							return [promiseResult.reason.key, promiseResult.reason.error]
					})
					.filter(Boolean),
			),
		}
	}

	convertRows(
		rows: IRow[],
		callback?: (total: number, completed: number) => void,
	): Promise<IMappingResult> {
		let completed = 0
		return Promise.all(
			rows.map((row, index) =>
				this.convertRow(row, index).then((res) => {
					completed++
					callback(rows.length, completed)
					return res
				}),
			),
		)
	}
}

export default MappingSchemaRunner
