import { StaticFormType } from "utils/services/StaticFormsService"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { PROP_REQUIRED } from "../../core/MappingSchemaRunner"
import IMappingTool from "../IMappingTool"
import formatNfdLowerCase from "utils/formatNfdLowerCase"

const stockPlaceFromText: IMappingTool<never, "field", never> = {
	id: "stockPlaceFromText",
	name: "Lieu de stockage",
	config: {},
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [JsonSchemaPropertiesType.STOCKPLACE],
	builder: ({ jsonSchemaProperty, points }) => {
		const stockPlaces = Object.values(points).filter(
			(point) =>
				point.jsonschema_id === StaticFormType.STOCK_PLACE ||
				point.jsonschema_id === StaticFormType.CARE,
		)
		return ({ singleInputs: { field } }) => {
			if (!field)
				return jsonSchemaProperty.isRequired
					? Promise.reject(PROP_REQUIRED)
					: Promise.resolve("")

			const stockPlace = stockPlaces.find(
				(sp) => formatNfdLowerCase(sp.geojson.properties.Lieu) === formatNfdLowerCase(field),
			)

			if (stockPlace) return Promise.resolve(stockPlace.id)
			else
				return Promise.reject({
					cause: "notfound",
					message: `"${field}" n'est pas un lieu de stockage connu`,
				})
		}
	},
}

export default stockPlaceFromText
