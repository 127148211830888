import React, { useEffect, useState } from "react"
import CustomTabsBase, { CustomTabsBaseProps } from "./CustomTabsBase"

interface CustomTabsProps extends CustomTabsBaseProps {
	forceTab?: number
}

const CustomTabs = (props: CustomTabsProps) => {
	const [currentTab, setCurrentTab] = useState(0)

	useEffect(() => {
		if (props.forceTab) {
			setCurrentTab(props.forceTab)
		}
	}, [props.forceTab])

	return (
		<CustomTabsBase
			currentTab={currentTab}
			setCurrentTab={setCurrentTab}
			{...props}
		/>
	)
}

export default CustomTabs
