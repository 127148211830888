import { Button } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useEffect, useRef, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { POINT_FORMAT_FIELD_REGEX } from "utils/components/jsonSchema/usePointFormat"
import { Flex } from "utils/components/style/flex"
import NewStyledInputLayout from "utils/form/formUtils/NewStyledInputLayout"

const Editor = styled.div`
    border: none;
    outline: none;
    padding: 16.5px 14px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
const FieldsLabel = styled.div`
    font-size: var(--font-size-small);
    font-weight: 500;
    padding-left: 14px;
    white-space: nowrap;
`

const SButton = styled(Button)`
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`

const createFieldNode = (field: string) => {
	const node = document.createElement("span")
	node.style.display = "inline-block"
	node.style.padding = "0 2px"
	node.style.margin = "0 1px"
	node.style.backgroundColor = "lightgrey"
	node.style.borderRadius = "2px"
	node.innerText = field
	node.setAttribute("field", field)
	node.contentEditable = "false"
	return node
}

const FPointFormat = ({ name, label, jsonSchemaId }) => {
	const { control } = useFormContext()
	const jsonSchemas = useJsonSchemasWithStatics()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})
	const internalFormat = useRef<string>(undefined)
	const internalJsonSchemaId = useRef<string>(undefined)

	// setting initialHTML resets contenteditable
	const [initialHTML, setInitialHTML] = useState<string>(undefined)
	// ---
	const [options, setOptions] = useState([])

	useEffect(() => {
		const valueChangedExternaly = value !== internalFormat.current
		const jsonSchemaChanged = jsonSchemaId !== internalJsonSchemaId.current

		// do not reset when
		if (value !== undefined && !valueChangedExternaly && !jsonSchemaChanged)
			return
		// ---

		const jsonSchema = jsonSchemas[jsonSchemaId]
		if (!jsonSchema) {
			internalJsonSchemaId.current = undefined
			setOptions([])
			setInitialHTML(undefined)
			return
		}

		const format =
			value && (!jsonSchemaChanged || valueChangedExternaly)
				? (value as string)
				: jsonSchema.template.properties
						.filter((prop) => prop.isIdentifiant)
						.map((prop) => `#{${prop.name}}`)
						.join(" ")

		const initialHTML =
			format
				.split(POINT_FORMAT_FIELD_REGEX)
				.map((item, index) => {
					if (index % 2 === 0) {
						return item
					} else {
						return createFieldNode(item).outerHTML
					}
				})
				.join("") + " "

		internalFormat.current = format
		internalJsonSchemaId.current = jsonSchemaId
		setInitialHTML(initialHTML)
		setOptions(jsonSchema.template.properties.map((prop) => prop.name))

		if (!value) onChange(format)
	}, [value, jsonSchemaId])

	const ref = useRef<HTMLDivElement>(undefined)

	const insert = (field: string) => {
		const selection = window.getSelection()
		const range = selection.getRangeAt(0)

		if (!selection.containsNode(ref.current, true)) {
			if (ref.current.lastChild) {
				range.setStartAfter(ref.current.lastChild)
				range.collapse(true)
			} else {
				range.setStart(ref.current, 0)
				range.collapse(true)
			}
		}

		range.collapse()
		const newNode = createFieldNode(field)
		range.insertNode(newNode)
		range.setStartAfter(newNode)
		const text = document.createTextNode("\u00A0")
		range.insertNode(text)
		range.setStartAfter(text)
	}

	const save = () => {
		const result = Array.from(ref.current.childNodes)
			.map((node) => {
				if (node.nodeType === Node.TEXT_NODE) {
					return node.textContent
				}
				if (node.nodeType === Node.ELEMENT_NODE) {
					const span = node as HTMLSpanElement
					const field = span.getAttribute("field")
					return field ? `#{${field}}` : ""
				}
				return ""
			})
			.join("")
			.trim()

		internalFormat.current = result
		onChange(result)
	}

	useEffect(() => {
		const observer = new MutationObserver(save)

		observer.observe(ref.current, {
			attributes: true,
			childList: true,
			subtree: true,
			characterData: true,
		})

		return () => observer.disconnect()
	}, [])

	return (
		<Wrapper>
			<NewStyledInputLayout
				label={label}
				error={Boolean(error)}
				helperText={error?.message}
				empty={!value}
				disabled={!options.length}
			>
				<Editor
					contentEditable={true}
					ref={ref}
					onKeyDown={(ev) => {
						if (ev.key === "Enter") ev.preventDefault()
					}}
					onPaste={(ev) => ev.preventDefault()}
					dangerouslySetInnerHTML={{ __html: initialHTML }}
				></Editor>
			</NewStyledInputLayout>

			{Boolean(options.length) && (
				<Flex gap="5px">
					<FieldsLabel>Champs disponibles :</FieldsLabel>
					<Flex wrap gap="2px">
						{options.map((field) => (
							<SButton
								key={field}
								onMouseDown={(ev) => ev.preventDefault()}
								onClick={() => insert(field)}
							>
								{field}
							</SButton>
						))}
					</Flex>
				</Flex>
			)}
		</Wrapper>
	)
}

export default FPointFormat
