import { Autocomplete } from "@mui/material"
import styled from "styled-components"

const VerticalAutocomplete = styled(Autocomplete)`
    & .MuiInputBase-root {
        flex-direction: column;
        align-items: flex-start;

        & input {
            width: auto !important;
            align-self: stretch !important;
        }
    }
`

export default VerticalAutocomplete
