import moment from "moment"
import { useCallback } from "react"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import IJsonSchema, { ISortBy } from "@/utils/types/IJsonSchema"
import IPoint from "utils/types/IPoint"
import {
	JsonSchemaPropertiesType,
	IJsonSchemaProperty,
} from "@/utils/types/IJsonSchema"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import IMultiPolygon from "utils/types/IMultiPolygon"
import _ from "lodash"

type IChainSortReturn = (
	chain: ISortBy[],
	buildAccessor: (sortItem: ISortBy) => (point: IPoint) => string,
) => (args: {
	a: IPoint
	b: IPoint
	forcedProperty?: ISortBy
	multiPolygons?: { [key: string]: IMultiPolygon }
}) => number

const sortDependingOnJsonSchemaProperty = ({
	a,
	b,
	jsonSchema,
	sortBy,
	pointToText,
	multiPolygons,
}: {
	a: IPoint
	b: IPoint
	jsonSchema: IJsonSchema
	sortBy: ISortBy
	pointToText: (point: IPoint, property: IJsonSchemaProperty) => string
	multiPolygons: { [key: string]: IMultiPolygon }
}) => {
	let pointTextA,
		pointTextB = undefined
	switch (
		jsonSchema.template.properties.find(
			(property) => property?.name === sortBy?.property,
		)?.type
	) {
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.TEXTAREA:
		case JsonSchemaPropertiesType.CREATABLE_SELECT:
		case JsonSchemaPropertiesType.PHONE:
		case JsonSchemaPropertiesType.DATE:
		case JsonSchemaPropertiesType.TIME:
		case JsonSchemaPropertiesType.TAGS:
		case JsonSchemaPropertiesType.STOCKPLACE:
		case JsonSchemaPropertiesType.RESPONSABLES:
		case JsonSchemaPropertiesType.NUMBER:
			pointTextA = pointToText(
				a,
				jsonSchema.template.properties.find(
					(property) => property?.name === sortBy.property,
				),
			)

			pointTextB = pointToText(
				b,
				jsonSchema.template.properties.find(
					(property) => property?.name === sortBy.property,
				),
			)
			break
		case JsonSchemaPropertiesType.GEOLOC:
			pointTextA = a?.geojson?.properties?.[sortBy.property]?.secteur
			pointTextB = b?.geojson?.properties?.[sortBy.property]?.secteur
			if (!pointTextA && !pointTextB) {
				return 0
			}
			if (!pointTextA) {
				return 1
			}
			if (!pointTextB) {
				return -1
			}
			if (pointTextA === "Hors secteur" && pointTextB === "Hors secteur") {
				return 0
			}
			if (pointTextA === "Hors secteur") {
				return 1
			}
			if (pointTextB === "Hors secteur") {
				return -1
			}
			pointTextA = multiPolygons[pointTextA]?.name
			pointTextB = multiPolygons[pointTextB]?.name
			break
		case JsonSchemaPropertiesType.SELECT:
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
		case JsonSchemaPropertiesType.RADIO:
			const indexA = jsonSchema.template.properties
				.find((property) => property.name === sortBy.property)
				.items.indexOf(a?.geojson?.properties?.[sortBy?.property])
			const indexB = jsonSchema.template.properties
				.find((property) => property.name === sortBy.property)
				.items.indexOf(b?.geojson?.properties?.[sortBy?.property])
			if (indexA === -1 && indexB === -1) {
				return 0
			}
			if (indexA === -1) {
				return 1
			}
			if (indexB === -1) {
				return -1
			}
			return indexA - indexB
		default:
			return 0
	}
	pointTextA = formatNfdLowerCase(pointTextA)
	pointTextB = formatNfdLowerCase(pointTextB)
	if (_.isEmpty(pointTextA) && _.isEmpty(pointTextB)) {
		return 0
	}
	if (_.isEmpty(pointTextA)) {
		return -1
	}
	if (_.isEmpty(pointTextB)) {
		return -1
	}
	return pointTextA.localeCompare(pointTextB)
}

const useSortPoints = (jsonSchema: IJsonSchema) => {
	const pointToText = usePointPropertyToText()
	if (!jsonSchema) {
		return () => 0
	}

	const buildAccessor = (sortItem: ISortBy) => {
		const property = jsonSchema.template.properties.find(
			(prop) => prop.name === sortItem.property,
		)
		return (point: IPoint) => pointToText(point, property)
	}

	const chainSort: IChainSortReturn = (chain: ISortBy[], buildAccessor) => {
		return ({
			a,
			b,
			forcedProperty,
			multiPolygons,
		}: {
			a: IPoint
			b: IPoint
			forcedProperty?: ISortBy
			multiPolygons: { [key: string]: IMultiPolygon }
		}) => {
			let comp = undefined
			if (!forcedProperty && chain?.length === 0) {
				return moment(b?.updated_at).unix() - moment(a?.updated_at).unix()
			}
			if (forcedProperty) {
				comp = sortDependingOnJsonSchemaProperty({
					a,
					b,
					jsonSchema,
					sortBy: forcedProperty,
					pointToText,
					multiPolygons,
				})
			}
			if (comp) {
				return comp
			}
			if (chain?.length === 0) {
				return moment(b.updated_at).unix() - moment(a.updated_at).unix()
			}
			comp = sortDependingOnJsonSchemaProperty({
				a,
				b,
				jsonSchema,
				sortBy: chain[0],
				pointToText,
				multiPolygons,
			})
			const next = chainSort(chain.slice(1), buildAccessor)
			return comp
				? comp
				: next({
						a,
						b,
						forcedProperty,
						multiPolygons,
				  })
		}
	}

	return chainSort(jsonSchema.sort_by, buildAccessor)
}

export default useSortPoints
